import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Main from './pages/main';
import Registerform from './pages/register';
import Thankyou from './pages/thankyou';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route  path='/' element={<Main/>}/>
        <Route path='/register' element={<Registerform/>}/>
        <Route path='/success' element={<Thankyou/>}/>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
