import React from 'react';
import {FaWhatsappSquare} from 'react-icons/fa';
import video from '../assets/landing_page_vid.mp4';
import book from '../assets/images/EBOOK K.png';
import pdf from '../assets/E-BOOK(PDF).pdf';

function Thankyou() {
  return (
    <div className=' bg-green-200'>
    <div className='flex flex-col overflow-hidden justify-center items-center text-center pt-[90px] px-5'>
      <div className=' relative'>
        <div className='flex flex-col'>
            <h1 className=' text-3xl md:text-5xl lg:text-5xl font-bold'>CONGRATULATIONS!!!</h1>
            <p className='md:text-3xl lg:text-3xl pt-3 capitalize'>You Have successfully registered for the webinar</p>
        </div>
        <div className='flex flex-col lg:mx-20 justify-center items-center pt-10 text-green-900 lg:gap-5 gap-3'>
          <a className='flex flex-col justify-center items-center overflow-hidden rounded-xl bg-green-500' href='https://chat.whatsapp.com/GSi96O1zLhpKNS33QjXEeH' target='blank'>
            <div className='h-80 w-full flex overflow-hidden'>
              <img className='h-auto w-full' src='https://images.unsplash.com/photo-1587573578181-f3342274f4c7?q=80&w=1965&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' alt='whatsapp' />
            </div>
            <div className='rounded-lg lg:w-96 text-white lg:h-32 md:w-96 shadow-md md:h-32 w-56 h-24 flex flex-row justify-center items-center lg:text-left md:text-left text-left px-5  lg:gap-3 gap-2'>
              <div className='text-[40px] lg:text-[50px] md:text-[50px]'><FaWhatsappSquare /></div>
              <p className='text-xl'>Click to join WhatsApp group</p>
            </div>
          </a>
        </div>
        <div className='pt-5 flex flex-col justify-center items-center'>
        <div className=' relative px-5 md:px-40 lg:px-40 transition-all ease-in-out hover:-translate-y-2 hover:scale-105  duration-500'>
          <video className={`rounded-lg shadow-xl w-[800px] transition-all duration-300`} h-auto controls>
            <source className='w-[400px] h-auto shadow-xl' src={video} type="video/mp4"/>
          </video>
        </div>
            <p className='pt-3'>Make sure You watch This Video</p>
            <div className='lg:text-5xl md:text-4xl text-2xl  italic p-10 flex flex-col'>"This is the most beautiful feeling for me, when i get to guide and hello hundreds of students"<div className='text-xl mt-4 lg:pl-[600px] md:pl-[400px] font-serif'>- Eldo George Varghese</div></div>
        </div>
        <div className='flex flex-wrap gap-8 text-center justify-center items-center lg:mx-40 md:mx-20 mx-5 px-8 py-4 bg-green-300 rounded-xl'>
            <div className='rounded-xl'>
                <img className='md:h-72 w-auto rounded-xl' src={book} alt='img'/>
            </div>
            <div className='flex flex-col gap-5 justify-start'>
              <h1 className='text-3xl font-bold'>FREE E- BOOK TO MAKE IT HAPPEN</h1>
              <a href={pdf} className='px-4 py-2 bg-green-900 text-white rounded-lg' target='blank'><button>Download</button></a>
            </div>
        </div>
      </div>
      <div className='flex flex-col justify-center items-center content-center bg-green-900 p-5 mt-10 w-screen bottom-0'>
          <div className='text-white text-sm'>© Copyright Eldo George. All Rights Reserved</div>
      </div>
    </div>
  </div>
  )
}

export default Thankyou