import React from 'react'
import Hero from '../components/hero'
import Learn from '../components/learn'
import Register from '../components/register'
import Navbar from '../components/navbars'
import Landing from '../components/landing'

function Main() {
  return (
    <div>
        <Navbar/>
        <Landing/>
        <Hero/>
        <Learn/>
        <Register/>
    </div>
  )
}

export default Main