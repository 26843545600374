import React from 'react'

function Landing() {
  return (
    <div className='relative flex flex-col justify-center md:text-center items-center text-left bg-cover bg-center bg-green-900 overflow-hidden'>
        <div className='z-10 relative text-white lg:py-40 py-20 px-5'>
            <div className='md:text-7xl text-3xl font-bold md:px-24 md:-skew-x-6 opacity-90 text-center bg-green-600 py-3 rounded-md'>HEY VACATIONS ARE APPROACHING<br/>YOU LIKE TO BE IN THE SAME STATE</div>
            <div className='md:text-3xl text-xl font-bold mt-5 lg:mx-40 text-center md:-skew-x-6 py-5 text-red-600 rounded-md bg-white flex justify-center items-center'>IF YOU ARE A PARENT OF 15 YEARS AND ABOVE HEAR THIS VIDEO</div>
        </div>
        <img className=' opacity-70 absolute inset-0 object-cover w-full h-full' src='https://images.unsplash.com/photo-1507537509458-b8312d35a233?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' alt='img'/>
        
    </div>
  )
}

export default Landing