import React from 'react';
import image from '../assets/images/19 (1).jpg';
import video from '../assets/landing_page_vid1.mp4';

function Hero() {
  return (
    <div className='pt-24 overflow-hidden relative w-full'>
    <div className='relative w-full flex flex-col justify-center items-center'>
        <div className='relative flex z-10 flex-col justify-center items-center text-center md:px-20 px-5 gap-10'>
            <div className='flex z-10 flex-col justify-center items-center text-center text-zinc-900'>
                <div className='md:text-4xl text-3xl font-semibold capitalize pt-3 md:leading-[50px] leading-10 text-zinc-900'>Hi, my name is<br/><span className='md:text-7xl text-5xl font-bold text-zinc-950'>eldo george varghese</span><br/>I am india's first future readiness where i have helped 2000 plus students in achieving the  right career…</div>
            </div>
            <video className={`rounded-lg shadow-xl w-[600px] transition-all duration-300`} h-auto controls>
              <source className='w-[300px] lg:200px h-auto shadow-xl' src={video} type="video/mp4"/>
            </video>
        </div>
    </div>
    </div>
  )
}

export default Hero