import React from 'react';
import { Link } from 'react-router-dom';

{/* <div className='md:w-96 w-[300px] flex flex-col gap-8 text-center px-8 py-4 bg-green-300 rounded-xl'>
    <div className='h-72 overflow-hidden rounded-xl'>
        <img className='h-72 w-auto' src={book} alt='img'/>
    </div>
    <h1 className='text-3xl font-bold'>FREE E- BOOK TO MAKE IT HAPPEN</h1>
    <a href={pdf} className='px-4 py-2 bg-green-900 text-white rounded-lg' target='blank'><button>Download</button></a>
</div> */}

function Register() {
  return (
    <div className='flex flex-col justify-center items-center'>
        <div className='text-5xl font-bold text-green-950'>Register</div>
        <div className='flex flex-wrap justify-center items-center mt-10 gap-10 px-5'>
            <div className='flex flex-wrap gap-8 text-center justify-center items-center px-8 py-4 bg-green-300 rounded-xl'>
                <div className=' h-40 md:w-80 overflow-hidden rounded-xl'>
                    <img className=' md:-translate-y-0 -translate-y-10' src='https://images.pexels.com/photos/5684562/pexels-photo-5684562.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' alt='img'/>
                </div>
                <div className='flex flex-col gap-5 justify-start'>
                  <h1 className='text-3xl font-bold'>REGISTER FOR THE WEBINAR</h1>
                  <Link  className='px-4 py-2 bg-green-900 text-white rounded-lg' to='/register' target='blank'>
                    <button>Register</button>
                  </Link>
                </div>
            </div>
        </div>
        <div className='flex flex-col justify-center items-center text-center content-center bg-green-900 p-5 mt-20 w-full bottom-0'>
          <div className='text-white text-sm'>© Copyright Eldo George. All Rights Reserved</div>
        </div>
    </div>
  )
}

export default Register