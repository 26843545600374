import React from 'react';
import sucess from '../assets/icons/success.png';
import career from '../assets/icons/career.png';
import validation from '../assets/icons/verification.png';
import habit from '../assets/icons/habit.png';
import academics from '../assets/icons/academics.png';
import video from '../assets/landing_page_vid1.mp4';

function Learn() {
  return (
    <div className='flex flex-col justify-center items-center md:py-40 py-20 gap-10'>
        {/* <div className=' relative px-5 md:px-40 lg:px-40 transition-all ease-in-out hover:-translate-y-2 hover:scale-105  duration-500'>
          <video className={`rounded-lg shadow-xl w-[800px] transition-all duration-300`} h-auto controls>
            <source className='w-[400px] h-auto shadow-xl' src={video} type="video/mp4"/>
          </video>
        </div> */}
        <div className='md:text-5xl text-3xl font-bold text-center pt-20 text-green-950'>WHAT WILL LEARN IN THE<br/>VACATION PROGRAM</div>
        <div className='flex flex-wrap justify-center items-start gap-10'>
            <div className='md:w-96 w-[300px] md:h-64 h-72 flex flex-col gap-3 justify-center items-center text-center bg-green-300 px-8 py-2 rounded-xl'>
                <img className='w-20' src={sucess} alt="Success" />
                <h1 className='text-2xl font-semibold'>BRAIN REWIRE FOR SUCCESSS</h1>
                <p>Structure brain in a manner as if like a warrior.</p>
            </div>
            <div className='md:w-96 w-[300px] md:h-64 h-72 flex flex-col gap-3 justify-center items-center text-center bg-green-300 px-8 py-2 rounded-xl'>
                <img className='w-20' src={career} alt="Career"/>
                <h1 className='text-2xl font-semibold'>CAREER SELECTION</h1>
                <p>Selecting the right career at the right time is so important that makes the process so successful.</p>
            </div>
            <div className='md:w-96 w-[300px] md:h-64 h-72 flex flex-col gap-3 justify-center items-center text-center bg-green-300 px-8 py-2 rounded-xl'>
                <img className='w-20' src={validation} alt='validation'/>
                <h1 className='text-2xl font-semibold'>CAREER VALIDATION</h1>
                <p>To know what is the availability of particular career in the market and the future is so important to make it up.</p>
            </div>
            <div className='md:w-96 w-[300px] md:h-64 h-72 flex flex-col gap-3 justify-center items-center text-center bg-green-300 px-8 py-2 rounded-xl'>
                <img className='w-20' src={habit} alt='habit' />
                <h1 className='text-2xl font-semibold'>LIFESTYLE HABITS THAT YOU NEED TO ADOPT</h1>
                <p>The rewiring habits that will help you to elevate to the next class of society.</p>
            </div>
            <div className='md:w-96 w-[300px] md:h-64 h-72 flex flex-col gap-3 justify-center items-center text-center bg-green-300 px-8 py-2 rounded-xl'>
                <img className='w-20' src={academics} alt='academics'/>
                <h1 className='text-2xl font-semibold'>HOW TO LINK BACK TO THE ACADEMICs</h1>
                <p>When they get how to link back to the basics everything becomes so easy to make it in.</p>
            </div>
        </div>
    </div>
  )
}

export default Learn